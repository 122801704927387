import React from 'react';
import "./footer.css";
import Social from '../home/Social';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="container">
                <div className="footer__container container">
                    <h1 className="footer__title">Achraf EL Kennouch</h1>
                    <div className="footer__social">
                        <Social />
                    </div>
                    <span className="footer__copy">© 2023 Designed By Achraf EL Kennouch</span>
                </div>
            </div>
        </footer>
    );
}

export default Footer;