import React from "react";
import './skills.css';
import TechSkills from "./TechSkills";
import DevEnvTools from "./DevEnvTools";


const Skills = () => {
    return ( <>
    <section className="skills section" id="skills">
<div className="container">
    <h2 className="section__title">Skills</h2>
    <span className="section__subtitle">My Skills</span>
    <div className="skills__container container grid">
      <TechSkills/>      
      <DevEnvTools />
    </div>
    </div>
    </section>
    </> );
}

export default Skills;