import React from "react";


const Social = () => {
    return (
        <>
            {/* <div className="home__social"> */}
            <a href="https://www.linkedin.com/in/achraf-el-kennouch-43847619b/" className="home__social-icon" target="">
                <i class="uil uil-linkedin"></i>
            </a>
            <a href="https://github.com/achrafelkennouch" className="home__social-icon">
                <i className="uil uil-github"></i>
            </a>
            <a href="https://twitter.com/ElKennouch" className="home__social-icon" target="">
                <i className="uil uil-twitter"></i>
            </a>
            {/* </div> */}
        </>
    );
}

export default Social;