import React from 'react';
import Projects from './Projects';
import "./projects.css";

const  Portfolio = () => {
  return (
    <section className="portfolio section" id="projects">
        <div className="container">
        <h2 className="section__title">My Projects</h2>
        <span className="section__subtitle">Recent Projects</span>

        <Projects />
        </div>
    </section>
  );
}

export default Portfolio;