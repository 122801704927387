import Project1 from "../../assets/MyProjects/Gym_App.png";
import Project2 from "../../assets/MyProjects/Real_Estate_App.png";
import Project3 from "../../assets/MyProjects/PFE.png";
import Project4 from "../../assets/MyProjects/Employee_management.png";

export const projectsData = [
    {
        // id: 4,
        image: Project4,
        title: "Employee Management",
        link : "https://www.youtube.com/watch?v=fXpbf4VwHmM&ab_channel=AchrafE.Kennouch",
        description:  "Forged through my journey of mastering Laravel, my Employee Management App seamlessly integrates features like Employee Management, Bonus Allocation, Employee Attendance Tracking, and Leave Requests  Management. Crafted with Laravel, Bootstrap, this project showcases my dedication to both effective HR solutions and web development.",
        buttonClass : "project__button",
    },
    {
        // id: 1,
        image: Project1,
        title: "Gym App",
        link : "https://achrafelkennouch-gym-app-react.pages.dev/",
        description:  "My React-driven gym web app is a personal project born out of a journey to master React. Featuring SignUp, Pricing, and Coaches sections.",
        buttonClass : "project__button",
    },
    {
        // id: 2,
        image: Project2,
        title: "Real Estate App",
        link : "https://modern-realestat-app.pages.dev/",
        description: "Forged through my quest to master React, my Real Estate web app seamlessly integrates features like Home, About, Services, Blog, Pricing, and Contact. Crafted with HTML, CSS, and JavaScript, this project reflects my dedication to both real estate and web development.",
        buttonClass : "project__button",
    },
    {
        // id: 3,
        image: Project3,
        title: "PFE",
        link : "#",
        description: "During my final internship from April to May 2023 at the Prefecture of Marrakech, I spearheaded the development of a comprehensive web application for the Regional Monitoring and Coordination Center (CVCR) of the Wilaya. Using technologies such as Laravel, Bootstrap, HTML, CSS, and JavaScript, I crafted a sophisticated event management system, including user and role management functionalities. This project, representing my graduation work, showcases a practical application of my web development skills to address the specific needs of the institution",
        buttonClass : "project__button__disabled",
    },
];

// export const projectsNav = [
//     {
//         name: "All",
//     },
//     {
//         name: "A",
//     },
//     {
//         name: "B",
//     },
//     {
//         name: "C",
//     },
// ];