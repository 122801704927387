import React, { useState } from 'react';

const ProjectItems = ({ item }) => {
  const [showMore, setShowMore] = useState(false);

  return (
    <div className="project__card">
      <img className="project__img" src={item.image} alt="" />
      <p className="project__description">
        {showMore ? item.description : `${item.description.substring(0, 150)}...`}
        <p className="showmore"onClick={() => setShowMore(!showMore)}>
          {showMore ? 'Show less' : 'Show more'}
        </p>
      </p>
      <div className='hanb'>
        <h3 className="project__title">{item.title}</h3>
        <a href={item.link} className={item.buttonClass}>
          Demo
        </a>
      </div>
    </div>
  );
};

export default ProjectItems;
