import React, { useState, useEffect } from "react";
import './header.css';

const Header = () => {
    // Toggle Menu
    const [Toggle, showMenu] = useState(false);
    // Toggle Menu
    // const [toggle, showMenu] = useState(false);
    const [isDarkMode, setIsDarkMode] = useState(false);

    useEffect(() => {
        // Update CSS variables based on dark mode state
        document.documentElement.style.setProperty('--title-color', isDarkMode ? '#f5eadf' : 'hsl(var(--hue), var(--sat), 20%)');
        document.documentElement.style.setProperty('--title-color-dark', isDarkMode ? '#74e0f0' : 'hsl(var(--hue), var(--sat), 0%)');
        document.documentElement.style.setProperty('--text-color', isDarkMode ? '#f5eadf' : 'hsl(var(--hue), var(--sat), 46%)');
        document.documentElement.style.setProperty('--box-shadow-color', isDarkMode ? '#74e0f0' : 'grey');
        document.documentElement.style.setProperty('--body-color', isDarkMode ? '#11011e' : 'hsl(var(--hue), var(--sat), 98%)');
        document.documentElement.style.setProperty('--container-color', isDarkMode ? '#47046b' : '#fff');
    }, [isDarkMode]);

    const toggleDarkMode = () => {
        setIsDarkMode((prevMode) => !prevMode);
    };
    return (
        <>
            {/* <header className="header container"> */}
            <header className="header">
                <nav className="nav container">
                    <a href="#home" className="nav__logo">Achraf EL Kennouch</a>
                    
                    <div className={Toggle ? "nav__menu show-menu" : "nav__menu"}>
                        <ul className="nav__list grid">
                            <li className="nav__item">
                                <a href="#home" className="nav__link active-link" onClick={() => showMenu(!Toggle)}>
                                    <i className="uil uil-estate nav__icon"></i>Home
                                </a>
                            </li>
                            <li className="nav__item">
                                <a href="#about" className="nav__link" onClick={() => showMenu(!Toggle)}>
                                    <i className="uil uil-user nav__icon"></i>About
                                </a>
                            </li>
                            <li className="nav__item">
                                <a href="#skills" className="nav__link" onClick={() => showMenu(!Toggle)}>
                                    <i className="uil uil-file nav__icon"></i>Skills
                                </a>
                            </li>

                            <li className="nav__item">
                                <a href="#projects" className="nav__link" onClick={() => showMenu(!Toggle)}>
                                    <i className="uil uil-scenery nav__icon"></i>Projects
                                </a>
                            </li>
                            <li className="nav__item">
                                <a href="#contact" className="nav__link" onClick={() => showMenu(!Toggle)}>
                                    <i className="uil uil-message nav__icon"></i>Contact
                                </a>
                            </li>
                            <li className="nav__item" onClick={toggleDarkMode}>
                                <span className="dark-mode-button" onClick={toggleDarkMode}>                  
                                <i className={isDarkMode ? 'uil uil-sun nav__icon' : 'uil uil-moon nav__icon'} onClick={toggleDarkMode}></i> 
                                <span onClick={toggleDarkMode} >
                                {isDarkMode ? 'Light Mode' : 'Dark Mode'}
                                </span>
                                </span>  
                            </li>
                        </ul>

                        <i className="uil uil-times nav__close" onClick={() => showMenu(!Toggle)}></i>

                    </div>
                    <div className="nav__toggle" onClick={() => showMenu(!Toggle)}>
                        <i className="uil uil-apps"></i>
                    </div>

                </nav>
            </header>
        </>
    );
}

export default Header;