import React from "react";
import phpLogo from "../../assets/logos/php.png";
import jsLogo from "../../assets/logos/js.png";
import sqlLogo from "../../assets/logos/sql.png";
import htmlLogo from "../../assets/logos/html.png";
import cssLogo from "../../assets/logos/css.png";
import bootstrapLogo from "../../assets/logos/bootstrap.png";
import reactLogo from "../../assets/logos/react.png";
import laravelLogo from "../../assets/logos/laravel.png";

const list = [
  { id: 1, cover: phpLogo, name: "PHP" },
  { id: 2, cover: jsLogo, name: "JS" },
  { id: 3, cover: sqlLogo, name: "SQL" },
  { id: 4, cover: htmlLogo, name: "HTML" },
  { id: 5, cover: cssLogo, name: "CSS" },
  { id: 6, cover: bootstrapLogo, name: "Bootstrap" },
  { id: 7, cover: reactLogo, name: "React" },
  { id: 8, cover: laravelLogo, name: "Laravel" },

];

const TechSkills = () => {
    return (
      <>
        <div className="skills__content">
          <h3 className="skills__title">Technical Skills</h3>
  
          <div className="skills__box">
            <div className="skills__group">
              {list.map((skill) => (
                <div className="skills__data" key={skill.id}>
                  <img src={skill.cover} alt={skill.name} />
                  <h3 className="skills__name">{skill.name}</h3>
                </div>
              ))}
            </div>
          </div>
        </div>
      </>
    );
  };
  
  export default TechSkills;
  