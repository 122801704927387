import React from "react";
import mysqlLogo from "../../assets/logos/mysql.png";
import mongodbLogo from "../../assets/logos/mongodb.png";
import linuxLogo from "../../assets/logos/linux.png";
// import windowsLogo from "../../assets/logos/windows.png";
import umlLogo from "../../assets/logos/uml.png";
import agileScrumLogo from "../../assets/logos/scrum.png";
import gitLogo from "../../assets/logos/git.png";
import githubLogo from "../../assets/logos/github.png";
import dockerLogo from "../../assets/logos/docker.png";

const list = [
    { id: 9, cover: mysqlLogo, name: "MySQL" },
    { id: 10, cover: mongodbLogo, name: "MongoDB" },
    { id: 11, cover: linuxLogo, name: "Linux" },
    // { id: 12, cover: windowsLogo, name: "Windows" },
    { id: 13, cover: umlLogo, name: "UML" },
    { id: 14, cover: agileScrumLogo, name: "Scrum" },
    { id: 15, cover: gitLogo, name: "Git" },
    { id: 16, cover: githubLogo, name: "GitHub" },
    { id: 17, cover: dockerLogo, name: "Docker" },

];

const DevEnvTools = () => {
    return (
      <>
        <div className="skills__content">
          <h3 className="skills__title">Dev Env & Tools</h3>
  
          <div className="skills__box">
            <div className="skills__group">
              {list.map((skill) => (
                <div className="skills__data" key={skill.id}>
                  <img src={skill.cover} alt={skill.name} />
                  <h3 className="skills__name">{skill.name}</h3>
                </div>
              ))}
            </div>
          </div>
        </div>
      </>
    );
  };
  
  export default DevEnvTools;
  