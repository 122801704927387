import React from 'react';
import './scrollup.css';

const ScrollUp = () => {
    const handleScrollUp = () => {
        window.scrollBy({
            top: -window.innerHeight, // Adjust this value based on your desired scroll distance
            behavior: 'smooth'
        });
    };

    window.addEventListener("scroll", function () {
        const scrollUp = document.querySelector(".scrollup");
        if (this.scrollY >= 560) scrollUp.classList.add("show-scroll");
        else scrollUp.classList.remove("show-scroll");
    });

    return (
        <button className="scrollup" onClick={handleScrollUp}>
            <i className="uil uil-arrow-up scrollup__icon"></i>
        </button>
    );
}

export default ScrollUp;
